import React, { useState, useEffect} from 'react';

import './App.css';

import ViewAppWeb from './Component/Views/ViewAppWeb.js'
import ViewLoading from './Component/Views/ViewLoading.js'

const App = () => {

  const [isLoading, setIsLoading] = useState(true)

useEffect(() => {
  setTimeout(() => {
    setIsLoading(false);
  }, 3000); 
},[])

  return (
    <div>
      {
        isLoading ? ( <ViewLoading/>
      ) : ( <ViewAppWeb/> )
      }
    </div>
  );

}

export default App;