import React from "react";

import './OurBigDay.css';

import {  Image } from "react-bootstrap";

const OurBigDay = ({retardo}) => {
    return (
        <section className="position-relative">
            {
                retardo ? ( 
                <div>
                    <Image className="imagen-responsive" src="/img/seccion2.svg"></Image>
                    <a className="position-absolute bottom-position-where1" href='https://www.google.com/maps/place/Parroquia+Mar%C3%ADa+Auxiliadora/@-33.3447595,-60.2074195,15z/data=!4m6!3m5!1s0x95b75d81c62286f5:0xfc5e5e615e51ce5f!8m2!3d-33.3447595!4d-60.2074195!16s%2Fg%2F11c32bknmf?entry=ttu' target="_blank" alt='Ubicación Iglesia'>
                        <Image className="imagen-responsive imagen-proporcional" src="/img/where1.svg"/>
                    </a>
                    <a className="position-absolute bottom-position-where2" href='https://www.google.com/maps/place/De+La+Quinta+Eventos/@-33.35629,-60.2148728,15z/data=!4m6!3m5!1s0x95b7677b88e87e87:0xa805754541267ae0!8m2!3d-33.35629!4d-60.2148728!16s%2Fg%2F11bw4vzb_y?entry=ttu' target="_blank" alt='Ubicación Iglesia'>
                        <Image className="imagen-responsive imagen-proporcional" src="/img/where2.svg"/>
                    </a> 
                </div>)
                : (<Image className="imagen-responsive" src="/img/seccion2.svg"></Image>)
            }                     
        </section>
    )
}

export default OurBigDay;