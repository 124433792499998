import React from "react";

import { Container, Row } from "react-bootstrap";


const ViewLoading = () => {
    return (
        <Container>
            <Row className="text-center">
                <p className="t-loading"> ♥ ♥ ♥ </p>
            </Row>
        </Container>
            
    )
}

export default ViewLoading;