import React from "react";


import { Image } from "react-bootstrap";

const AboutUs = () => {
    return (
        <section className="dposition-relative">
            <Image className="imagen-responsive" src="/img/seccion1.svg"></Image>

        </section>
    )
}

export default AboutUs;