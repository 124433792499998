import React, {useState, useEffect} from "react";

import HeaderComponent from '../../Component/Header/HeaderComponent.js'
import AboutUs from '../../Component/Section/AboutUs.js'
import OurBigDay from '../../Component/Section/OurBigDay.js'
import ConfirmAssist from '../../Component/Section/ConfirmAssist.js'
import WishList from '../../Component/Section/WishList.js'
import FooterInstagram from '../../Component/Section/FooterInstagram.js'

const ViewAppWeb = () => {

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setTimeout(() => {
          setIsLoading(true);
        }, 3000); 
      },[])

    return (
        <div className="App">
            <HeaderComponent />
            <AboutUs />
            <OurBigDay retardo={isLoading}/>
            <ConfirmAssist retardo={isLoading}/>
            <WishList retardo={isLoading}/>
            <FooterInstagram retardo={isLoading}/>
        </div>
    )

}

export default ViewAppWeb;