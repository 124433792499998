import React from "react";

import './ConfirAssist.css';

import { Image,  Button } from "react-bootstrap";

const ConfirmAssist = ({retardo}) => {
    return (
        <section className="position-relative">
            {
                retardo ? (
                    <div>
                        <Image className="imagen-responsive" src="/img/seccion3.svg"></Image>
                        <Button href="https://docs.google.com/forms/d/1nlQPbQ0Xh6cnV8kTxXSJ0NzwilF4AtmSRtcRR7yJqFM/edit" target="_blank" variant="warning" className="position-absolute button-position-confirm col-1" >
                            <b>HACELO ACÁ</b>
                        </Button>
                    </div>
                ) :
                (<Image className="imagen-responsive" src="/img/seccion3.svg"></Image>)
            }
            
        </section>
    )
}

export default ConfirmAssist;