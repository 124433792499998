import React from "react";
import './FooterInstagram.css';

import { Image } from "react-bootstrap";

const FooterInstagram = ({retardo}) => {
    return (
        <section className="position-relative">
        {
            retardo ? (
                <div>
                    <Image className="imagen-responsive" src="/img/seccion5.svg"></Image>
                    <a className="position-absolute bottom-position-insta" href='https://www.instagram.com/bodanoeyjavi?igsh=NXp6ZzQ1ODJlMWht&utm_source=qr' target="_blank" alt='Ubicación Iglesia'>
                        <Image className="imagen-responsive imagen-proporcional" src="/img/instagram.svg"/>
                    </a>  
                 </div>
            ) : (<Image className="imagen-responsive" src="/img/seccion5.svg"></Image> )
        }
                      
        </section>
    )
}

export default FooterInstagram;