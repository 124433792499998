import React from "react";

import './WishList.css';

import { Image, Button } from "react-bootstrap";

const WishList = ({retardo}) => {
    return (
        <section className="position-relative">
        {
            retardo ? ( 
                <div> 
                <Image className="imagen-responsive" src="/img/seccion4.svg" />
                <Button variant="warning" className="position-absolute button-position-wish col-2" href="https://gift.bodanoeyjavi.com/" target="_blank">
                    <b>ELEGÍ UN REGALO</b>  
                </Button>
                </div>
          ) : (<Image className="imagen-responsive" src="/img/seccion4.svg" />)
          }
          </section>
    )
}

export default WishList;