import React from "react";


import { Image } from "react-bootstrap";

const HeaderComponent = () => {
    return (
        <header>
            <section className="position-relative">
            <Image className="imagen-responsive" src="/img/inicio.svg"></Image>
            </section>
        </header>
    )
}

export default HeaderComponent;